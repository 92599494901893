import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const Buro = () => {
  useEffect(() => {
    document.body.className = "persons";
  }, []);

  return (
    <main style={{ width: "100vw", paddingBottom: 40 }}>
      <Container id="buro" style={{ height: "90%", paddingTop: "5.5rem" }}>
        <Row className={"mb-5 w-75 m-auto"}>
          <Col className={"text-center"} xs={12} sm={12} md={12} lg={12}>
            <h1>Buró de Entidades Financieras</h1>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <h6 className="mb-2 mt-2">
              {"¿Qué es el Buró de Entidades Financieras?"}
            </h6>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <p>
              Es una herramienta de consulta y difusión con la que podrás
              conocer los productos que ofrecen las entidades financieras, sus
              comisiones y tasas, las reclamaciones de los usuarios, las
              prácticas no sanas en que incurren, las sanciones administrativas
              que les han impuesto, las cláusulas abusivas de sus contratos y
              otra información que resulte relevante para informarte sobre su
              desempeño. Con el Buró de Entidades Financieras, se logrará saber
              quién es quién en bancos, seguros, sociedades financieras de
              objeto múltiple, cajas de ahorro, afores, entre otras entidades.
              Esta información te será útil para elegir un producto financiero y
              también para conocer y usar mejor los que ya tienes. Con ello,
              podrás comparar y evaluar a las entidades financieras, sus
              productos y servicios y tendrás mayores elementos para elegir lo
              que más te convenga. Este Buró de Entidades Financieras es una
              herramienta que puede contribuir al crecimiento económico del
              país, al promover la competencia entre las instituciones
              financieras; que impulsará la transparencia al revelar información
              a los usuarios sobre el desempeño de éstas y los productos que
              ofrecen y que va a facilitar un manejo responsable de los
              productos y servicios financieros al conocer a detalle sus
              características. Lo anterior, podrá derivar en un mayor bienestar
              social, porque al conjuntar en un solo espacio tan diversa
              información del sistema financiero, el usuario tendrá más
              elementos para optimizar su presupuesto, para mejorar sus finanzas
              personales, para utilizar correctamente los créditos que
              fortalecerán su economía y obtener los seguros que la protejan,
              entre otros aspectos.
            </p>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <h6>
              COMPORTAMIENTO GENERAL DE BANCO PAGATODO, S.A. INSTITUCIÓN DE
              BANCA MÚLTIPLE.
            </h6>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <p>
              El Buró de Entidades Financieras contiene información de Banco
              PagaTodo sobre el desempeño frente a los usuarios por la
              prestación de productos y servicios. Podrás consultar la
              información que consta del Banco en: Entidades Financieras a
              través de la siguiente liga:{" "}
              <a
                href="https://www.buro.gob.mx/general_gob.php?id_sector=40&id_periodo=41"
                target="_blank"
                rel="noreferrer"
              >
                https://www.buro.gob.mx/general_
                <wbr />
                gob.php?id_sector=40&id_periodo=41
              </a>
            </p>
            <br></br>
            <a
              href="/pdfs/InformaciónBuroEntidadesFinancieras.pdf"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "darkgreen",
                fontWeight: "bold",
              }}
            >
              Cifras de Banco PagaTodo obtenidas en el Buró de entidades Financieras por el año 2024.
            </a>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <h6>Información del sector</h6>
          </Col>
          <Col className="my-2" xs={12} sm={12} md={12} lg={12}>
            <p>
              Para conocer información de todo el sector,podrás acceder al sitio
              del Buró de entidades financieras que se encuentra en la siguiente
              liga:{" "}
              <a
                href="https://www.buro.gob.mx"
                target="_blank"
                rel="noreferrer"
              >
                https://www.buro.gob.mx
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Buro;
